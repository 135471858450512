import { Component } from '@angular/core';
import { IonNav, MenuController } from '@ionic/angular';
import { SettingsComponent } from './settings/settings/settings.component';

@Component({
  selector: 'app-main',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public readonly nav: IonNav,
    public readonly menu: MenuController,
  ) {
  }

  public async openSettings(): Promise<boolean> {
    await this.menu.close('first');
    if ((await this.nav.getActive()).component === SettingsComponent) {
      return false;
    }
    return this.nav.push(SettingsComponent);
  }

  public async closeSettings(): Promise<boolean> {
    return this.nav.popToRoot();
  }
}

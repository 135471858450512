import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Action, Actions, ofActionSuccessful, Selector, State, StateContext, Store, UpdateState } from '@ngxs/store';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LoadSettings, ResetSettings, StoreSettings } from './settings.actions';
import { Settings } from './settings.model';
import { SettingsService } from './settings.service';

@State<Settings>({
  name: 'settings',
  defaults: {
    theme: 'light',
    locale: 'en',
  },
})
@Injectable({
  providedIn: 'root',
})
export class SettingsState implements OnDestroy {
  private readonly subs: Subscription[] = [];
  private readonly INIT_STATE = {};

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private actions: Actions,
    private service: SettingsService,
    private store: Store,
  ) {
    this.subs.push(this.actions.pipe(ofActionSuccessful(StoreSettings))
      .pipe(tap(({ settings }: StoreSettings) => {
        if (settings.theme === 'dark') {
          this.document.body.classList.remove();
          this.document.body.classList.add('dark');
        } else {
          this.document.body.classList.remove('dark');
        }
      }))
      .subscribe());

    this.subs.push(this.actions.pipe(ofActionSuccessful(UpdateState))
      .pipe(tap(({ addedStates }: UpdateState) => {
        _.assign(this.INIT_STATE, addedStates);
      }))
      .subscribe());
  }

  @Selector()
  public static settings(settings: Settings): Settings {
    return _.cloneDeep(settings);
  }

  @Action({ type: '@@INIT' })
  public ngxsInit(ctx: any, action: any) {
    console.log(ctx, action);
  }

  @Action(ResetSettings, { cancelUncompleted: true })
  public reset(ctx: StateContext<Settings>) {
    return this.service.reset()
      .pipe(switchMap(() => ctx.dispatch(new StoreSettings({
        theme: 'light',
        locale: 'en',
      }))))
      .pipe(tap(() => this.store.reset(this.INIT_STATE)));
  }

  @Action(LoadSettings, { cancelUncompleted: true })
  public loadSettings(ctx: StateContext<Settings>) {
    return this.service.load()
      .pipe(tap(settings => ctx.setState(settings)));
  }

  @Action(StoreSettings, { cancelUncompleted: true })
  public storeSettings(ctx: StateContext<Settings>, { settings }: StoreSettings) {
    return this.service.store(settings)
      .pipe(tap(() => ctx.setState(settings)));
  }

  public init(): Observable<Settings> {
    return this.service.load()
      .pipe(switchMap(settings => this.store.dispatch(new StoreSettings(settings))
        .pipe(map(() => settings))));
  }

  public ngOnDestroy(): void {
    _.filter(this.subs, sub => !_.isNil(sub) && !sub.closed)
      .forEach(sub => sub.unsubscribe());
  }
}

import _ from 'lodash';
import { Settings } from './settings.model';

export class ResetSettings {
  public static readonly type = '[Settings] Reset';
}

export class LoadSettings {
  public static readonly type = '[Settings] Load';
}

export class StoreSettings {
  public static readonly type = '[Settings] Store';
  public readonly settings: Settings;

  constructor(
    settings: Settings,
  ) {
    this.settings = _.cloneDeep(settings);
  }
}

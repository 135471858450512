import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import _ from 'lodash';
import { Entity } from './state.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage;

  constructor(
    private service: Storage,
  ) {
    //
  }

  public async getStorage(): Promise<Storage> {
    if (_.isNil(this.storage)) {
      this.storage = await this.service.create();
    }
    return this.storage;
  }

  public async list<T extends Entity<T>>(key: string): Promise<T[]> {
    const raw = await this.storage.get(key);
    return _.cloneDeep(_.isNil(raw) ? [] : raw);
  }

  public async get<T extends Entity<T>>(key: string, id: number): Promise<T> {
    const entity = _.find(await this.list(key), { id }) as never;
    if (_.isNil(entity)) {
      throw new HttpErrorResponse({ statusText: 'Not found!', status: 404 });
    }
    return entity;
  }

  public async create<T extends Entity<T>>(key: string, entity: T): Promise<T> {
    if (!_.isNil(entity.id)) {
      throw new HttpErrorResponse({ statusText: 'Conflict!', status: 409 });
    }
    const entities = await this.list(key);
    _.set(entity, 'id', Date.now());
    _.set(entity, 'createdAt', new Date(entity.id));
    entities.push(entity);
    await this.set(key, entities);
    return this.get(key, entity.id);
  }

  public async update<T extends Entity<T>>(key: string, { id, ...entity }: T): Promise<T> {
    if (_.isNil(await this.get(key, id))) {
      throw new HttpErrorResponse({ statusText: 'Not found!', status: 404 });
    }
    const entities = await this.list(key);
    _.assign(_.find(entities, { id }), entity);
    await this.set(key, entities);
    return this.get(key, id);
  }

  public async delete(key: string, id: number): Promise<void> {
    if (_.isNil(await this.get(key, id))) {
      throw new HttpErrorResponse({ statusText: 'Not found!', status: 404 });
    }
    const instruments = _.filter(await this.list(key), e => e.id !== id);
    await this.set(key, instruments);
  }

  public async clear(key?: string): Promise<void> {
    if (_.isNil(key)) {
      return this.storage.clear();
    }
    return this.set(key, []);
  }

  public async false(key: string): Promise<void> {
    return this.storage.set(key, false);
  }

  public async true(key: string): Promise<void> {
    return this.storage.set(key, true);
  }

  public async is(key: string): Promise<boolean> {
    return await this.storage.get(key) === true;
  }

  private async set<T extends Entity<T>>(key: string, entities: T[]): Promise<void> {
    return this.storage.set(key, _.cloneDeep(entities));
  }
}

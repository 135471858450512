import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'settings-nav',
  template: '<ion-nav [root]="root"></ion-nav>',
})
export class SettingsNavComponent {
  public root = AppComponent;
}

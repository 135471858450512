import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResetSettings, StoreSettings } from '../settings.actions';
import { Settings } from '../settings.model';
import { SettingsState } from '../settings.state';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnDestroy {
  public readonly settings: Partial<Settings> = {};

  private readonly sub: Subscription;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private actions: Actions,
    public store: Store,
  ) {
    this.sub = this.actions.pipe(ofActionSuccessful(StoreSettings))
      .pipe(tap(({ settings }: StoreSettings) => this.patch(settings)))
      .subscribe();
    this.patch(this.store.selectSnapshot(SettingsState.settings));
  }

  public storeSettings(): void {
    this.store.dispatch(new StoreSettings(this.settings as Settings));
  }

  public reset(): void {
    this.store.dispatch(new ResetSettings());
  }

  public patch(settings: Settings) {
    _.keys(this.settings).forEach(key => {
      if (_.isNil(_.get(settings, key))) {
        delete this.settings[key];
      }
    });
    _.assign(this.settings, settings);
  }

  public ngOnDestroy(): void {
    if (!_.get(this.sub, 'closed', true)) {
      this.sub.unsubscribe();
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IonFormsResolver {
  public static forms;

  constructor(
    private httpClient: HttpClient,
  ) {
    //
  }

  public proxyDriveRequest(
    id: string,
    target = 'https://drive.google.com',
    path = '/uc?export=download&id=',
  ) {
    return this.httpClient.get(
      `https://develop.baltasaar.com/user-service/proxy${path}${id}`,
      {
        headers: {
          'X-Original-Hostname': target,
        },
      },
    ).pipe(take(1));
  }

  public proxyRequest(
    id: string,
    target = '',
    path = '/assets/forms/',
  ) {
    return this.httpClient.get(`${target}${path}${id}`)
      .pipe(take(1));
  }

  public resolve(paths: { [key: string]: string }): Observable<any> {
    const forms = {};
    return forkJoin(_.map(_.values(paths), id => this.proxyRequest(id)))
      .pipe(map((res: any[]) => {
        _.keys(paths)
          .forEach((path, i) =>
            _.set(forms, path, _.get(res, i, {})));
        IonFormsResolver.forms = _.cloneDeep(forms);
        return forms;
      }));
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'instruments',
  },
  {
    path: 'instruments',
    loadChildren: () => import('./instruments/instruments.module')
      .then(m => m.InstrumentsModule),
  },
  {
    path: 'groups',
    loadChildren: () => import('./groups/groups.module')
      .then(m => m.GroupsModule),
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.module')
      .then(m => m.ServicesModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module')
      .then(m => m.SettingsModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

import { CommonModule, DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { SettingsNavComponent } from './settings.nav.component';
import { SettingsService } from './settings.service';
import { SettingsState } from './settings.state';
import { SettingsComponent } from './settings/settings.component';

@NgModule({
  declarations: [
    SettingsNavComponent,
    SettingsComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgxsModule.forFeature([
      SettingsState,
    ]),
    FormsModule,
  ],
  providers: [
    SettingsService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (state: SettingsState, document: Document) => async () => state.init().toPromise().then(() => true),
      deps: [
        SettingsState,
        DOCUMENT,
      ],
    },
  ],
  exports: [
    SettingsNavComponent,
  ],
})
export class SettingsModule {
}

import { Injectable } from '@angular/core';
import _ from 'lodash';
import { from, Observable } from 'rxjs';
import { StorageService } from '../state/storage.service';
import { Settings } from './settings.model';

@Injectable()
export class SettingsService {
  constructor(
    private service: StorageService,
  ) {
  }

  public store(settings: Settings): Observable<void> {
    return from(this.service.getStorage()
      .then(storage => storage.set('settings', settings)));
  }

  public load(): Observable<Settings> {
    return from(this.service.getStorage()
      .then(storage => storage.get('settings'))
      .then(settings => _.isNil(settings) ? {
        theme: 'light',
        locale: 'en',
      } : settings));
  }

  public reset(): Observable<void> {
    return from(this.service.getStorage()
      .then(storage => storage.clear()));
  }
}

import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import _ from 'lodash';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppRoot } from './app.root';
import { IonFormsResolver } from './forms/ion.forms.resolver';
import { SettingsModule } from './settings/settings.module';

@NgModule({
  declarations: [
    AppComponent,
    AppRoot,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    AppRoutingModule,
    BrowserAnimationsModule,
    SettingsModule,
    HttpClientModule,
  ],
  providers: [
    IonFormsResolver,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (resolver: IonFormsResolver) => async () => {
          const paths = {
            // 'service.create': '1HVojcJ-eaVAig7Yk_oC9HKzLGLWPtF4W',
            // 'group.create': '1gwgd0CoqqeAZ7yAcdmCVPngVQXL7cMk_',
            // 'instrument.create': '1TGfMoGiAWjWBgET7Gk8PT8i2YNrUZaFc',
            'instrument.create': 'instrument.create.json',
            'ico.create': 'ico.create.json',
            'group.create': 'group.create.json',
            'service.create': 'service.create.json',
          };
          return resolver.resolve(paths)
            .toPromise().then((forms) => ! _.isNil(forms));
        },
      deps: [
        IonFormsResolver,
      ],
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
  ],
  bootstrap: [
    AppRoot,
  ],
})
export class AppModule {
}
